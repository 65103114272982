<template>
  <div class="mid_autummn">
    <img src="@/assets/zhongqiu/Slice7.png" alt="">
    <img src="@/assets/zhongqiu/Slice8.png" alt="">
    <img src="@/assets/zhongqiu/Slice9.png" alt="">
    <img src="@/assets/zhongqiu/Slice10.png" alt="">
  </div>
</template>

<script>
 export default {
    data () {
      return {

      }
    },
    created() {
      this.init();
    },
    methods: {
       init() {

       }
     }
 }
</script>

<style lang="less" scoped>
    .mid_autummn {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        img {
            width: 100%;
            display: block;
        }
    }
</style>
